<template>
  <div :data-block-id="id" :data-block-type="__typename">
    <component :is="viewComponent" v-bind="viewProps" />
  </div>
</template>

<script lang="ts" setup>
import useGallery from '@base/composables/use-gallery'
import type { GalleryHorizontalProps } from '@base/components/gallery/horizontal.vue'
import type { GalleryVerticalProps } from '@base/components/gallery/vertical.vue'
import type { GalleryCarouselProps } from '@base/components/gallery/carousel.vue'
import type { CmsGallery } from '#gql/default'

const GALLERY_COMPONENTS = {
  CmsGalleryCarouselBlock: 'GalleryCarousel',
  CmsGalleryVerticalBlock: 'GalleryVertical',
  CmsGalleryHorizontalBlock: 'GalleryHorizontal',
} as const

type GalleryBlockTypes = keyof typeof GALLERY_COMPONENTS

export type CmsBlockGalleryProps = {
  id: string
  __typename: GalleryBlockTypes
  renderAboveTheFold: boolean
  gallery: CmsGallery
  autoplay?: boolean
  infinite?: boolean
  slideDuration?: number
  hideDescription: boolean
  hideLink: boolean
  hideTitle: boolean
  galleryLayout?: string
  allowZoom?: boolean
  imageSize?: string
  imageFormat?: string
  pattern?: string
}

const {
  id,
  __typename,
  renderAboveTheFold = false,
  gallery,
  autoplay = false,
  infinite = false,
  slideDuration,
  hideDescription = false,
  hideLink = false,
  hideTitle = false,
  allowZoom = false,
  imageSize,
  imageFormat,
  pattern,
} = defineProps<CmsBlockGalleryProps>()

const { mapGallerySlides } = useGallery()

const { viewComponent, viewProps } = useGetViewComponentConfig(__typename)

function useGetViewComponentConfig(__typename: GalleryBlockTypes) {
  const baseViewProps = {
    renderAboveTheFold,
    hideDescription,
    hideLink,
    hideTitle,
  }

  const slides = mapGallerySlides(gallery.slides)

  switch (__typename) {
    case 'CmsGalleryCarouselBlock': {
      return {
        viewComponent: resolveComponent('GalleryCarousel'),
        viewProps: {
          ...baseViewProps,
          slides,
          autoplay,
          infinite,
          slideDuration,
        } satisfies GalleryCarouselProps,
      }
    }
    case 'CmsGalleryVerticalBlock': {
      return {
        viewComponent: resolveComponent('GalleryVertical'),
        viewProps: {
          ...baseViewProps,
          slides,
          imageSize,
          imageFormat,
          pattern,
          allowZoom,
        } satisfies GalleryVerticalProps,
      }
    }
    case 'CmsGalleryHorizontalBlock': {
      return {
        viewComponent: resolveComponent('GalleryHorizontal'),
        viewProps: {
          ...baseViewProps,
          slides,
          imageSize,
          imageFormat,
          allowZoom,
        } satisfies GalleryHorizontalProps,
      }
    }
  }
}

defineOptions({
  name: 'CmsBlockGallery',
})
</script>
